import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';
import ProductBadge from '../ProductBadge';
import ProductPrice from '../ProductPrice';
import AddToBasketButtonForTeasers from '../AddToBasketButtonForTeasers';
import Button from '../Button';
import DefaultPicture, {
  propTypes as pictureProps,
} from '../../components/01_atoms/DefaultPicture';
import { CategoriesContext } from '../../utils/context';
import { DEFAULT_COUNTRY_CODE } from '../../utils/constants';

import s from './index.module.scss';

const GiftItem = ({ product, variant = 'default' }) => {
  const categories = useContext(CategoriesContext);

  const highlightedCategories = product.categoryIds.filter(
    (id) => categories[id]?.highlightedColor,
  );

  const targetUrl =
    product.type === 'gift_santa_letter' && product.landingPageUrl.nextLink
      ? product.landingPageUrl.nextLink.url
      : product.url.url;

  const image =
    DEFAULT_COUNTRY_CODE === 'GB' && product.tileImageUK ? product.tileImageUK : product.image;
  return (
    <div
      className={`${s['gift-item']} ${s[`gift-item--${variant}`]} ${s[`gift-item--${product.type}`]}`}
      data-test="gift-item"
    >
      <div className={s['gift-item__content']}>
        <Link href={targetUrl}>
          <a className={s['gift-item__link']}>
            {product.isNew && (
              <div className={s['gift-item__new']}>
                <div className={s['gift-item__new-content']}>New!</div>
              </div>
            )}
            <div className={s['gift-item__image']}>
              {product.image && <DefaultPicture {...image} loading="lazy" />}
              {variant === 'default' && <ProductBadge product={product} />}
            </div>
            <div className={s['gift-item__details']}>
              <h3 className={s['gift-item__title']} data-test="gift-item-title">
                {product.title}
              </h3>
              <div className={s['gift-item__price']} data-test="gift-item-price">
                <ProductPrice product={product} />
              </div>
              {product.shortDescription && (
                <div className={s['gift-item__description']}>{product.shortDescription}</div>
              )}

              {highlightedCategories && variant === 'modern' && (
                <div className={s['gift-item__highlighted']}>
                  {highlightedCategories.map((categoryId) => (
                    <span
                      key={categoryId}
                      className={
                        s['gift-item__highlighted-' + categories[categoryId].highlightedColor]
                      }
                    >
                      {categories[categoryId].title}
                    </span>
                  ))}
                </div>
              )}
              {product.ecardActive && variant === 'modern' && !product.postalCardActive && (
                <div className={s['gift-item__ecard']}>E-Card only</div>
              )}
            </div>
          </a>
        </Link>
        <div className={s['gift-item__button']}>
          {product.type !== 'gift_santa_letter' && (
            <AddToBasketButtonForTeasers product={product} />
          )}
          {product.type === 'gift_santa_letter' && (
            <Link href={targetUrl}>
              <Button
                data-test="create-your-own-button-for-teasers"
                type="primary"
                isOutlined
                isMobileBlock
                size="small"
              >
                Create your own
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

GiftItem.propTypes = {
  variant: PropTypes.oneOf(['default', 'modern']),
  product: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    categoryIds: PropTypes.arrayOf(PropTypes.number),
    image: PropTypes.shape(pictureProps),
    actionImage: PropTypes.shape(pictureProps),
    tileImageUK: PropTypes.shape(pictureProps),
    url: PropTypes.object,
    shortDescription: PropTypes.string,
    ecardActive: PropTypes.bool,
    postalCardActive: PropTypes.bool,
    isNew: PropTypes.bool,
    landingPageUrl: PropTypes.object,
  }).isRequired,
};

export default GiftItem;
